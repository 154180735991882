import React from "react"
import get from "lodash/get"
import replace from "lodash/replace"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"

import { makeStyles } from "@material-ui/core/styles"
import { graphql, Link } from "gatsby"
import { FacebookShareButton } from "react-share"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import FbIcon from "../images/fb.svg"

import getNornalizeDate from '../helpers/getNornalizeDate'

const useStyles = makeStyles(theme => ({
  root: {
    padding: "180px 0",
    minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.down("md")]: {
      padding: "120px 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "80px 0",
    },
    "& table": {
      display: "block",
      maxWidth: "100%",
      overflowX: "auto",
    },
  },
  title: {
    margin: "100px 0",
    [theme.breakpoints.down("md")]: {
      margin: "70px 0",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "50px 0",
    },
  },
  details: {
    marginBotttom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      marginBottom: `${theme.spacing(7)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.spacing(5)}px`,
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  date: {
    fontSize: "50px",
    fontWeight: "300",
  },
  share: {
    color: theme.palette.text.disabled,
    marginRight: 10,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  gatsbyImage: {
    marginTop: theme.spacing(3),
    marginBotttom: theme.spacing(3),
  },
  facebookButton: {},
}))

const SingleBlog = props => {
  const classes = useStyles()
  const { blogTitle, blogContent, blogImage, blogDate } = get(
    props,
    "data.datoCmsBlogPage"
  )
  const image = getImage(blogImage)
  const normalizeDate = getNornalizeDate(blogDate)

  const ShareButton = () => (
    <FacebookShareButton
      url={props.location.href}
      className={classes.facebookButton}
    >
      <Box display="flex">
        <Typography variant="body1" className={classes.share}>
          Udostępnij na
        </Typography>
        <FbIcon />
      </Box>
    </FacebookShareButton>
  )

  return (
    <Layout>
      <Seo title={blogTitle} description="Kompleksowa obsługa księgowa, podatkowa i prawna | Monika Sochacka +48 795 995 368 | Warszawa"/>
      <Box className={classes.root} id="home">
        <Container maxWidth="xl">
          <GatsbyImage
            image={image}
            alt="Hero image"
            className={classes.gatsbyImage}
          />
          <Typography variant="h1" className={classes.title}>
            {blogTitle}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.details}
          >
            <Typography variant="body2" className={classes.date}>
              {normalizeDate}
            </Typography>
            <ShareButton />
          </Box>
          <Typography component="div" variant="body1">
            <span dangerouslySetInnerHTML={{ __html: blogContent }} />
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Link to="/blog/">
              <IconButton color="primary" aria-label="back button">
                <ChevronLeftIcon />
              </IconButton>
            </Link>
            <ShareButton />
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query querySingleBlog($id: String!) {
    datoCmsBlogPage(id: { eq: $id }) {
      blogTitle
      blogContent
      blogDate
      blogImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }
  }
`

export default SingleBlog
